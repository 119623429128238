import { render, staticRenderFns } from "./Dewars.vue?vue&type=template&id=1841aa6b&scoped=true&"
import script from "./Dewars.vue?vue&type=script&lang=js&"
export * from "./Dewars.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1841aa6b",
  null
  
)

export default component.exports